import { LinksFunction } from "@remix-run/node";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";

import { ErrorComponent } from "~/components/error";
import { TooltipProvider } from "~/components/ui/tooltip";
import globalStyles from "~/tailwind.css?url";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: globalStyles, as: "style" }];
export const shouldRevalidate = () => false;
export function loader() {
  return json({
    ENV: {
      TWILIO_CALLER_ID: process.env.TWILIO_CALLER_ID,
    },
  });
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>("root");
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="color-scheme" content="light only"></meta>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"></link>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"></link>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"></link>
        <link rel="manifest" href="/site.webmanifest"></link>
        <Meta />
        <Links />
      </head>
      <body className="sm:bg-secondary">
        {children}
        <ScrollRestoration />
        <Scripts />
        {data ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
            }}
          />
        ) : null}
      </body>
    </html>
  );
}

function AppWithProviders() {
  return (
    <TooltipProvider>
      <Outlet />
    </TooltipProvider>
  );
}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <ErrorComponent />;
}
